<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on }">
      <v-btn color="color3" text v-on="on">
        Import Schedule
      </v-btn>
    </template>
    <v-card>
      <v-toolbar color="color1 color1Text--text">
        <v-toolbar-title>Schedule Upload</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          color="color2 color2Text--text"
          small fab
          @click.stop="dialog = false"
          :disabled="loading"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="px-1">
        <match-importer
          :key="dialog"
          :tournament="tournament"
          @loading-change="onLoadingChange"
          @complete="dialog = false"
          :active="dialog"
        ></match-importer>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import MatchImporter from './MatchImporter'

export default {
  props: ['tournament'],
  data () {
    return {
      dialog: false,
      loading: false
    }
  },
  methods: {
    onLoadingChange (v) {
      this.loading = v
    }
  },
  components: {
    MatchImporter
  }
}
</script>
